import React, {useState, useEffect} from "react";
import {Card, Breadcrumb, Button, Row, Col, Accordion, Table} from "react-bootstrap";
import NotificationLA from "../../components/notifications/notificationLA";
import {ReactComponent as BackIcon} from '../../assets/img/back.svg'
import {ReactComponent as DashFilter1} from "../../assets/img/dash-filter-1.svg";
import {ReactComponent as DashFilter2} from "../../assets/img/dash-filter-2.svg";
import {ReactComponent as DashFilter3} from "../../assets/img/dash-filter-3.svg";
import {ReactComponent as DashFilter4} from "../../assets/img/dash-filter-4.svg";
import {ReactComponent as DashFilter6} from "../../assets/img/dash-filter-6.svg";
import {ReactComponent as TabShipperIcon} from '../../assets/img/tab-shipper-icon.svg'
import {ReactComponent as TabConsigneeIcon} from '../../assets/img/tab-consignee-icon-big.svg'
import {ReactComponent as TabAgentIcon} from '../../assets/img/tab-agent-icon-big.svg'
import {ReactComponent as TabChevronDown} from '../../assets/img/tab-chevron-down.svg'
import {ReactComponent as SortIcon} from '../../assets/img/sort.svg'
import {ReactComponent as SortPrimaryIcon} from '../../assets/img/sort-primary.svg'
import {ReactComponent as CargoIcon} from '../../assets/img/general-icon.svg'
import {ReactComponent as ContainersIcon} from '../../assets/img/containers.svg'
import {useHistory} from "react-router-dom";
import {FaEye, FaFileDownload} from 'react-icons/fa';
import {useSelector} from "react-redux";
import {dispatch} from "../../redux";
import {previewPDF, previewPdfSuccess} from "../../redux/action";
import PaginationComp from "../../components/pagination/PaginationComp";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import axios from "../../service/axiosConfig";
import {saveAs} from 'file-saver'
import {useFetchShipmentQuery} from "../../redux/queryAPI/customerWebsiteAPI";
import LoadingSpinner from "../../assets/css/spinners/LoadingSpinner";


const Shipment = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const shipmentId = (history.location.pathname.split('/')).slice(-1)[0];
    const {t} = useTranslation()
    const [pdfData, setPDFData] = useState('');
    const attachmentPDFData = useSelector((state) => state?.shipmentReducer?.AttachmentPDFDA);

    const {data, error, isLoading, isFetching, isSuccess} = useFetchShipmentQuery(shipmentId);

    useEffect(() => {
        if (attachmentPDFData?.length > 0) {
            var obj = document.createElement('object')
            obj.style.width = '100%';
            obj.style.height = '842pt';
            obj.type = 'application/pdf';
            obj.data = 'data:application/pdf;base64,' + attachmentPDFData;

            var link = document.createElement('a');
            link.innerHTML = 'Download PDF file';
            link.download = `${pdfData}.pdf`;
            link.href = 'data:application/octet-stream;base64,' + attachmentPDFData;
            link.click();
            dispatch(previewPdfSuccess(''));
        }
    }, [attachmentPDFData, pdfData])

    if (isFetching) {
        console.log('Fetching...')
    }

    if (isLoading) {
        console.log('Loading...')
        return <LoadingSpinner />;
    }

    if (error) {
        console.log('Error...')
        return <div>Error: {error}</div>;
    }

    if (isSuccess) {
        console.log('Success...', data)
    }


    const handlePreview = (id, scope, url, name) => {
        if (scope === null) {
            window.open(url)
        } else {
            setPDFData(name);
            dispatch(previewPDF(id, scope));
        }
    }

    const download = (id, scope, url, name) => {
        console.log('id, scope, url, name', id, scope, url, name);
        axios.get(process.env.REACT_APP_BASE_API_CALL + `shipment/download-attachment/${scope}/${id}`, {
            responseType: 'blob'
        })
            .then(response => {
                saveAs(response.data, name);
            }).catch((error) => {
            toast.error('Se ha producido un error al descargar el archivo', {
                position: "top-right",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "light",
            });
        })


        //console.log('RES', res);
    }


    const sessionsPerPage = 5;
    var allSessionsCount, lastSessionNumber, firstSessionIndex, limitedSessions, urlPath;
    if (data?.shipsGoReference) {
        if(data?.transportMode === "SEA"){
            urlPath = 'https://shipsgo.com/live-map-container-tracking-map-only?query=' + data?.shipsGoReference;
        } else if(data?.transportMode === "AIR") {
            urlPath = 'https://embed.shipsgo.com/?token=b9cb656c-cfb6-4907-8493-66830e180208&transport=air&query=176-00314381' + data?.shipsGoReference;
        }

    }
    if (data?.documents && data?.documents?.length >= 1) {
        allSessionsCount = data?.documents?.length;
        lastSessionNumber = currentPage * sessionsPerPage;
        firstSessionIndex = lastSessionNumber - sessionsPerPage;
        limitedSessions = data?.documents?.slice(firstSessionIndex, lastSessionNumber);
    }

    return (
        <React.Fragment>
            <div className="content">
                <Breadcrumb className='hot-shipment-action'>
                    <Breadcrumb.Item className='mr-3' onClick={history.goBack}> <BackIcon className="back-icon"/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {(data.transportMode === "SEA") ? t('Seafreight') : (data.transportMode === "AIR") ? t('Airfreight') : (data.transportMode === "LAND") ? t('Land_transport') : (data.transportMode === "COUR") ? t('Courier') : t('Shipment')} - {data.shipmentName}</Breadcrumb.Item>
                </Breadcrumb>
                <section className="page-content container-fluid">
                    <Row className='form-row'>
                        <Col xl={9}>
                            <Card>
                                <Card.Body>
                                    <Row className="form-row">
                                        <Col md={6} lg={3}>
                                            <Card>
                                                <Card.Body>
                                                    <h4 className='mb-0'>{(data?.customerReference !== null) ? t('Reference') : ''} {data?.customerReference}</h4>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Card>
                                                <Card.Body>
                                                    {(data?.status === "DELIVERED") ?
                                                        <h3 className='text-black mb-0'>{t('Delivered')}</h3> :
                                                        (data?.status === "PENDINGDELIVERY") ?
                                                            <h3 className='text-black mb-0'>{t('Arrived')}</h3> :
                                                            <h3 className='text-black mb-0'>{t('Arrival_estimated')} : {data.eta !== null ? data.eta : t('Pending')} </h3>
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={3}>
                                            <Card>
                                                <Card.Body>
                                                    <h4 className='mb-0'>{(data.transportMode === "SEA") ? t('BL') : (data.transportMode === "AIR") ? t('AWB') : (data.transportMode === "LAND") ? t('CMR') : (data.transportMode === "COUR") ? t('ISN') : ''} {data.blNumber}
                                                        <span className='text-black'> </span></h4>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="form-row card-deck">
                                        <Col md={3} className="mb-4 mb-xl-0">
                                            <Card className='m-0 text-md-center'>
                                                <Card.Body className='p-4'>
                                                    <div className='icon icon-primary d-block m-b-20'>

                                                        {(data?.transportMode === "SEA") ?
                                                            <DashFilter1/> : (data?.transportMode === "AIR") ?
                                                                <DashFilter2/> : (data?.transportMode === "LAND") ?
                                                                    <DashFilter3/> : (data?.transportMode === "COUR") ?
                                                                        <DashFilter4/> : <DashFilter6/>}
                                                    </div>
                                                    <h4 className='font-size-18 m-0'>
                                                        {(data.transportMode === "SEA") ? <>
                                                                <br/> {data.vesselName} </> :
                                                            (data.transportMode === "AIR") ? data.airlineName : (data.transportMode === "LAND") ? data.truckRegistrationNumber : (data.transportMode === "COUR") ? data.courierAgent : ""} </h4>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xl={9}>
                                            <Row className="form-row arrow-chevron" style={{minHeight: '156px'}}>
                                                <Col sm={4} md={3} className="custom-pr-0 mb-3 mb-md-0">
                                                    <Card className='m-0'>
                                                        <Card.Body>
                                                            <h4>{t('From')}</h4>
                                                            <h2 className='font-size-30 text-black mb-2'>{data.originCode}</h2>
                                                            <h4 className='font-size-18 text-black mb-2'>{data.originName}</h4>
                                                            <h5 className='m-0'> {data.etd}</h5>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col sm={4} md={3} className="custom-px-0 mb-3 mb-md-0">
                                                    <Card className='m-0 arrow-chevron text-sm-center'>
                                                        <Card.Body
                                                            className='card-body d-flex flex-column justify-content-center arrow-pointer-container'>
                                                            <h3 className='h5 text-black mb-2 mt-1'>{data.viaName}</h3>
                                                            <h5 className='m-0'>{data.transitTime} </h5>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col sm={4} md={3} className="custom-pl-0 mb-3 mb-md-0">
                                                    <Card className='m-0'>
                                                        <Card.Body>
                                                            <h4>{t('To')}</h4>
                                                            <h2 className='font-size-30 text-black mb-2'>{data.destinationCode}</h2>
                                                            <h4 className='font-size-18 text-black mb-2'>{data.destinationName}</h4>
                                                            <h5 className='m-0'> {data.eta}</h5>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={3} className="">
                                                    <Card className='m-0'>
                                                        <Card.Body>
                                                            <h4>{data.service}</h4>
                                                            <h2 className='font-size-30 text-black mb-2'>{data.trafficChargeType}</h2>
                                                            <h5 className='m-0'> {data.incoterm}</h5>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Card.Header className='border-0 px-0 mt-4'>
                                        <h3 className='mb-0'>{t('How_is_my_Shipment')}</h3>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <Card>
                                            <Card.Body className="p-4">
                                                <div className="shipment-time-line hot-shipment-timeline">
                                                    <div className="time-line">
                                                        {data.transportMode !== 'LAND' && data.transportMode !== 'COUR' && (
                                                            <>
                                                                <p className={data?.events?.booked !== null || data?.events?.loadedOnBoard !== null || data?.status ===  "DELIVERED"? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.booked} <br/>
                                                                    <span className="status">{t('BOOKED')}</span>
                                                                </p>
                                                            </>)}
                                                        {data.transportMode !== 'LAND' && data.transportMode !== 'COUR' && (data.trafficFlow !== 'import' || data.incoterm === 'EXW') && (<>
                                                            <p className={data?.events?.pickup !== null || data?.events?.loadedOnBoard !== null || data?.status === "DELIVERED" ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                {data?.events?.pickup} <br/>
                                                                <span className="status">{t('PICKUP')}</span>
                                                            </p>
                                                        </>)}
                                                        {data.transportMode !== 'LAND' && data.transportMode !== 'COUR' && (
                                                            <>
                                                                <p className={data?.events?.loadedOnBoard !== null || data?.status === "DELIVERED" ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.loadedOnBoard}<br/>
                                                                    <span className="status">{t('LOADEDONBOARD')}</span>
                                                                </p>
                                                                <p className={data?.events?.arrived !== null ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.arrived} <br/>
                                                                    <span className="status">{t('ARRIVED')}</span>
                                                                </p>
                                                            </>)}
                                                        {data.transportMode !== 'LAND' && data.transportMode !== 'COUR' && (data.trafficFlow === 'import' || data.incoterm === 'DAT' || data.incoterm === 'DAP' || data.incoterm === 'DDP') && (
                                                            <>
                                                                <p className={data?.events?.customsCleared !== null || data?.events?.delivery !== null ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.customsCleared} <br/>
                                                                    <span className="status">{t('Customs')}</span>
                                                                </p>
                                                                <p className={data?.events?.delivery !== null ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.delivery}
                                                                    <span className="status">{t('DELIVERED')}</span>
                                                                    &nbsp;
                                                                </p>
                                                            </>)}
                                                        {(data.transportMode === 'LAND' || data.transportMode === 'COUR') && (
                                                            <>
                                                                <p className={data?.events?.loadedOnBoard !== null ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.loadedOnBoard}<br/>
                                                                    <span className="status">Departed</span>
                                                                </p>
                                                                <p className={data?.events?.arrived !== null || data?.status === "DELIVERED" ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.arrived} <br/>
                                                                    <span className="status">Arrival</span>
                                                                </p>
                                                            </>)}
                                                        {(data.transportMode === 'LAND' || data.transportMode === 'COUR') && (data.trafficFlow === 'import' || data.incoterm === 'DAT' || data.incoterm === 'DAP' || data.incoterm === 'DDP') && (
                                                            <>
                                                                <p className={data?.events?.customsCleared !== null || data?.status === "DELIVERED" ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.customsCleared} <br/>
                                                                    <span className="status">{t('Customs')}</span>
                                                                </p>
                                                                <p className={data?.events?.delivery !== null || data?.status === "DELIVERED" ? "font-weight-normal text-primary-custom dote m-b-20 h6" : "font-weight-normal text-primary-custom dote m-b-20 h6 pending"}>
                                                                    {data?.events?.delivery}
                                                                    <span className="status">{t('DELIVERED')}</span>
                                                                    &nbsp;
                                                                </p>
                                                            </>)}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                    <Card.Header className='border-0 px-0 mt-4'>
                                        <h3 className='mb-0'>{t('Shipment_Details')}</h3>
                                    </Card.Header>
                                    <Card.Body className='p-0'>
                                        {/*<p className='text-primary-custom mb-4 h6 text-right'>{t('Client_Reference')}: <span*/}
                                        {/*    className='text-black'> {data?.customerReference} </span></p>*/}
                                        {data.trafficFlow !== 'cross-trade' && (
                                            <Row className="form-row" style={{minHeight: '156px'}}>
                                                <Col sm={6} md={4} className="mb-3 mb-md-0">
                                                    <Card className='m-0 shadow-sm min-h-100p'>
                                                        <Card.Body className='p-4'>
                                                            <div
                                                                className='d-flex align-items-center h-45 mb-3 mb-md-4 pb-0 pb-md-2'>
                                                                <TabShipperIcon
                                                                    className='shipper-icon w-100 mr-2 h-20'/>
                                                                <h4 className='m-0'>Shipper</h4>
                                                            </div>
                                                            <span className='d-block mb-2'>
                                                            {data?.shipper?.name}</span>
                                                            {data?.shipper?.street} <br/>
                                                            {data?.shipper?.zipCode} {" "}
                                                            {data?.shipper?.location} <br/>
                                                            {data?.shipper?.contactName}<br/>
                                                            {data?.shipper?.contactEmail} <br/>
                                                            {data?.shipper?.contactPhone}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col sm={6} md={4} className="mb-3 mb-md-0">
                                                    <Card className='m-0 shadow-sm min-h-100p'>
                                                        <Card.Body className='p-4'>
                                                            <div
                                                                className='d-flex align-items-center mb-3 pb-0 pb-md-2'>
                                                                <TabConsigneeIcon className='consignee-icon h-40 mr-2'/>
                                                                <h4 className='m-0'>Consignee</h4>
                                                            </div>
                                                            <span
                                                                className='d-block mb-2'> {data?.consignee?.name}</span>
                                                            {data?.consignee?.street} <br/>
                                                            {data?.consignee?.zipCode} {" "}
                                                            {data?.consignee?.location} <br/>
                                                            {data?.consignee?.contactName}<br/>
                                                            {data?.consignee?.contactEmail} <br/>
                                                            {data?.consignee?.contactPhone}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                {data.agent !== null && (
                                                    <Col sm={6} md={4} className="mb-3 mb-md-0">
                                                        <Card className='m-0 shadow-sm min-h-100p'>
                                                            <Card.Body className='p-4'>
                                                                <div
                                                                    className='d-flex align-items-center mb-3 mb-md-4 pb-0 pb-md-2'>
                                                                    <TabAgentIcon className='agent-icon h-40 mr-2'/>
                                                                    <h4 className='m-0'>{t('Agent')}</h4>
                                                                </div>
                                                                <span
                                                                    className='d-block mb-2'> {data?.agent?.name}</span>
                                                                {data?.agent?.street} <br/>
                                                                {data?.agent?.zipCode} {" "}
                                                                {data?.agent?.location} <br/>
                                                                {data?.agent?.contactName}<br/>
                                                                {data?.agent?.contactEmail} <br/>
                                                                {data?.agent?.contactPhone}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>)}
                                            </Row>)}
                                        {data.trafficFlow === 'cross-trade' && (
                                            <Row className="form-row" style={{minHeight: '156px'}}>
                                                <Col sm={6} md={5} className="mb-3 mb-md-0">
                                                    <Card className='m-0 shadow-sm min-h-100p'>
                                                        <Card.Body className='p-4'>
                                                            <div
                                                                className='d-flex align-items-center h-45 mb-3 mb-md-4 pb-0 pb-md-2'>
                                                                <TabShipperIcon
                                                                    className='shipper-icon w-100 mr-2 h-20'/>
                                                                <h4 className='m-0'>Shipper</h4>
                                                            </div>
                                                            <span className='d-block mb-2'>
                                                            {data?.shipper?.name}</span>
                                                            {data?.shipper?.street} <br/>
                                                            {data?.shipper?.zipCode} {" "}
                                                            {data?.shipper?.location} <br/>
                                                            {data?.shipper?.contactName}<br/>
                                                            {data?.shipper?.contactEmail} <br/>
                                                            {data?.shipper?.contactPhone}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col sm={6} md={5} className="mb-3 mb-md-0">
                                                    <Card className='m-0 shadow-sm min-h-100p'>
                                                        <Card.Body className='p-4'>
                                                            <div
                                                                className='d-flex align-items-center mb-3 pb-0 pb-md-2'>
                                                                <TabConsigneeIcon className='consignee-icon h-40 mr-2'/>
                                                                <h4 className='m-0'>Consignee</h4>
                                                            </div>
                                                            <span
                                                                className='d-block mb-2'> {data?.consignee?.name}</span>
                                                            {data?.consignee?.street} <br/>
                                                            {data?.consignee?.zipCode} {" "}
                                                            {data?.consignee?.location} <br/>
                                                            {data?.consignee?.contactName}<br/>
                                                            {data?.consignee?.contactEmail} <br/>
                                                            {data?.consignee?.contactPhone}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>)}
                                        {data.trafficFlow === 'cross-trade' && (
                                            <Row className="form-row" style={{minHeight: '156px'}}>
                                                <Col sm={6} md={5} className="mb-3 mb-md-0">
                                                    <Card className='m-0 shadow-sm min-h-100p'>
                                                        <Card.Body className='p-4'>
                                                            <div
                                                                className='d-flex align-items-center mb-3 mb-md-4 pb-0 pb-md-2'>
                                                                <TabAgentIcon className='agent-icon h-40 mr-2'/>
                                                                <h4 className='m-0'>{t('Origin agent')}</h4>
                                                            </div>
                                                            <span
                                                                className='d-block mb-2'> {data?.originAgent?.name}</span>
                                                            {data?.originAgent?.street} <br/>
                                                            {data?.originAgent?.zipCode} {" "}
                                                            {data?.originAgent?.location} <br/>
                                                            {data?.originAgent?.contactName}<br/>
                                                            {data?.originAgent?.contactEmail} <br/>
                                                            {data?.originAgent?.contactPhone}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col sm={6} md={5} className="mb-3 mb-md-0">
                                                    <Card className='m-0 shadow-sm min-h-100p'>
                                                        <Card.Body className='p-4'>
                                                            <div
                                                                className='d-flex align-items-center mb-3 mb-md-4 pb-0 pb-md-2'>
                                                                <TabAgentIcon className='agent-icon h-40 mr-2'/>
                                                                <h4 className='m-0'>{t('Destination agent')}</h4>
                                                            </div>
                                                            <span className='d-block mb-2'> {data?.agent?.name}</span>
                                                            {data?.agent?.street} <br/>
                                                            {data?.agent?.zipCode} {" "}
                                                            {data?.agent?.location} <br/>
                                                            {data?.agent?.contactName}<br/>
                                                            {data?.agent?.contactEmail} <br/>
                                                            {data?.agent?.contactPhone}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>)}
                                    </Card.Body>
                                    <Card.Header className='border-0 px-0 mt-0 mt-md-4'>
                                        <h3 className='mb-0'>{t('Merchandise_Details')}</h3>
                                    </Card.Header>
                                    <Card.Body className='p-0'>
                                        <Row className="form-row">
                                            <Col lg={6}>
                                                <Card className='m-0 shadow-sm'>
                                                    <Card.Body className='p-4 min-h-235'>
                                                        <h4 className='mb-4'>{t('Cargo')}</h4>
                                                        <div className='d-flex flex-wrap align-items-center max-w-450'>
                                                            <div className='text-center mr-1 mb-2'>
                                                                <CargoIcon className="cargo-icon"/>
                                                            </div>
                                                            <div className='flex-fill mt-2'>
                                                                <Row xs={2} className='form-row m-b-10'>
                                                                    <Col
                                                                        className='col-lg-6 text-primary-custom text-right font-weight-700'> {t('Quantity')}:</Col>
                                                                    <Col
                                                                        className='col-lg-6'>{data.totalPackages} {t('Packages')}</Col>
                                                                </Row>
                                                                <Row xs={2} className='form-row m-b-10'>
                                                                    <Col
                                                                        className='col-lg-6 text-primary-custom text-right font-weight-700'> {t('Weight')}:</Col>
                                                                    <Col
                                                                        className='col-lg-6'>{data.totalGrossWeight} Kg.</Col>
                                                                </Row>
                                                                <Row xs={2} className='form-row m-b-10'>
                                                                    <Col
                                                                        className='col-lg-6 text-primary-custom text-right font-weight-700'> {t('Volume')}:</Col>
                                                                    <Col
                                                                        className='col-lg-6'>{data.totalVolume} m3</Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                    <Accordion defaultActiveKey={['1']} alwaysopen="true">
                                                        <Accordion.Toggle as={Button} eventKey="1"
                                                                          className='bg-primary-custom d-flex align-items-center justify-content-between w-100p'>
                                                            <span>&nbsp; </span> {t('See_Details')} <TabChevronDown/>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="1">
                                                            <Table responsive borderless hover>
                                                                <thead>
                                                                <tr>
                                                                    <th>{t('Packages')}</th>
                                                                    <th>{t('Description')}</th>
                                                                    <th>{t('Weight')}</th>
                                                                    <th>{t('Volume')}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {data?.goods && data?.goods?.map((i, index) => (
                                                                    <tr key={index}>
                                                                        <td>{i.packages}</td>
                                                                        <td>{i?.description}</td>
                                                                        <td>{i?.grossWeight}</td>
                                                                        <td>{i?.volume}</td>

                                                                    </tr>))}
                                                                </tbody>
                                                            </Table>
                                                        </Accordion.Collapse>
                                                    </Accordion>
                                                </Card>
                                            </Col>
                                            {(data?.transportMode === "AIR") ? <Col lg={6}>
                                                <Card className='m-0 shadow-sm'>
                                                    <Card.Body className='p-4 min-h-235'>
                                                        <h4 className='mb-4'>{t('Flights')}</h4>
                                                        <div
                                                            className='d-flex flex-md-row align-items-center max-w-450'>
                                                            <div className='text-center m-r-30'>
                                                                <DashFilter2 className="containers-icon"/>
                                                            </div>
                                                            <div className='flex-fill'>
                                                                <h6 className='text-dark font-weight-normal'>
                                                                    <b>{data.originName}</b><br/> {data.etd}<br/><br/>
                                                                    <b>{data.destinationName}</b><br/> {data.eta}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                    <Accordion defaultActiveKey={['0']} alwaysopen="true">
                                                        <Accordion.Toggle as={Button} eventKey="0"
                                                                          className='bg-primary-custom d-flex align-items-center justify-content-between w-100p'>
                                                            <span>&nbsp; </span> {t('See_Details')} <TabChevronDown/>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="0">
                                                            <Table responsive borderless hover>
                                                                <thead>
                                                                <tr>
                                                                    <th>{t('Destination')}</th>
                                                                    <th>{t('Airline')}</th>
                                                                    <th>{t('Flight')}</th>
                                                                    <th>{t('Departure')}</th>
                                                                    <th>{t('Arrival')}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {data?.flights && data?.flights?.map((i, index) => (
                                                                    <tr key={index}>
                                                                        <td>{i?.destination}</td>
                                                                        <td>{i?.airline}</td>
                                                                        <td>{i?.flight}</td>
                                                                        <td>{i?.departure}</td>
                                                                        <td>{i?.arrival}</td>

                                                                    </tr>))}
                                                                </tbody>
                                                            </Table>
                                                        </Accordion.Collapse>
                                                    </Accordion>
                                                </Card>
                                            </Col> : (data?.transportMode === "SEA" && data?.trafficChargeType === "FCL") ?
                                                <Col lg={6}>
                                                    <Card className='m-0 shadow-sm'>
                                                        <Card.Body className='p-4 min-h-235'>
                                                            <h4 className='mb-4'>{t('Containers')}</h4>
                                                            <div
                                                                className='d-flex flex-md-row align-items-center max-w-450'>
                                                                <div className='text-center m-r-30'>
                                                                    <ContainersIcon className="containers-icon"/>
                                                                </div>
                                                                <div className='flex-fill'>
                                                                    <h3 className='text-dark font-weight-normal'>
                                                                        {data?.totalContainers && data?.totalContainers?.map((i, index) => (
                                                                            <tr key={index}>
                                                                                <td>{i}</td>

                                                                            </tr>))}
                                                                    </h3>
                                                                    <small> {t('TOTAL_OF')} {data.totalContainersNum} </small>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                        <Accordion defaultActiveKey={['0']} alwaysopen="true">
                                                            <Accordion.Toggle as={Button} eventKey="0"
                                                                              className='bg-primary-custom d-flex align-items-center justify-content-between w-100p'>
                                                                <span>&nbsp; </span> {t('See_Details')}
                                                                <TabChevronDown/>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Table responsive borderless hover>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>{t('Number')}</th>
                                                                        <th>{t('Type')}</th>
                                                                        <th>{t('Tag_No')}</th>
                                                                        <th>{t('Seal_No')}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {data?.containers && data?.containers?.map((i, index) => (
                                                                        <tr key={index}>
                                                                            <td>{i?.containerNumber}</td>
                                                                            <td>{i?.containerTypeName}’</td>
                                                                            <td>{i?.containerPlate}</td>
                                                                            <td>{i?.sealNumber}</td>

                                                                        </tr>))}
                                                                    </tbody>
                                                                </Table>
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </Card>
                                                </Col> : null}
                                        </Row>
                                    </Card.Body>
                                    <Card.Header className='border-0 px-0 mt-3 mt-md-4'>
                                        <h3 className='mb-0'>{t('Documentation')}</h3>
                                    </Card.Header>
                                    <Card.Body className='p-0'>
                                        <Card className='mb-0'>
                                            <div className='card-body'>
                                                <Table responsive borderless hover>
                                                    <thead>
                                                    <tr>
                                                        <th className="text-primary-custom">{t('Document_Type')}
                                                            <SortPrimaryIcon
                                                                className='sort-primary-icon align-baseline'/></th>
                                                        {/*<th>{t('Created_by')} <SortIcon className='align-baseline'/></th>*/}

                                                        <th>{t('Creation_date')}<SortIcon className='align-baseline'/>
                                                        </th>
                                                        <th>Preview/{t('Download')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {limitedSessions && limitedSessions?.map((i, index) => (
                                                        <tr key={index}>
                                                            <td>{i.type} {i.name}</td>
                                                            {/*<td>{i.createdBy}</td>*/}
                                                            <td>{i.creationDate}</td>
                                                            <td className="actions-dropdown">
                                                                {i.url !== null ?
                                                                    <FaEye className="iconPreview"
                                                                           onClick={() => handlePreview(i.id, i.scope, i.url, (i.downloadName))}/>
                                                                    :
                                                                    <FaFileDownload className="iconPreview"
                                                                                    onClick={() => {
                                                                                        download(i.id, i.scope, i.url, (i.downloadName))
                                                                                    }}/>
                                                                }
                                                            </td>
                                                        </tr>))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card>
                                        {(data?.documents && data?.documents?.length >= 1) ? <PaginationComp
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            alwaysShown={false}
                                        /> : null}
                                    </Card.Body>
                                </Card.Body>
                            </Card>
                            {(data?.shipsGoReference != null) ?
                                <Card>
                                    <Card.Header className='border-0'>
                                        <h3 className='mb-0'>Tracking</h3>
                                    </Card.Header>
                                    <Card.Body className='pt-0'>
                                        <Card>
                                            <Card.Body className="p-4">
                                                <div className="cus-div-con">
                                                    <iframe
                                                        src={urlPath}
                                                        title="iframeShipsgoLiveMap"
                                                        id="iframeShipsgoLiveMap"
                                                        style={{height: '500px', width: '100%'}}></iframe>

                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card> : null}
                        </Col>
                        <NotificationLA/>
                    </Row>
                </section>
            </div>
        </React.Fragment>)
}

export default Shipment;
